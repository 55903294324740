<template>
  <div>
    <general-table
      ref="gamingProductsTable"
      :api-url="APIURL"
      :delete-content="false"
      :view-content="false"
      :export-table="false"
      :edit-content="false"
      :type="type"
      :columns="columns"
      :guard="guard"
      :viw-component="viwComponent"
    >
      <template #cell(image)="data">

        <img
          :src="data.item.image"
          width="100"
        >
      </template>
      <template #cell(Assign)="data">

        <b-button
          variant="primary"
          @click="openCategoryModal(data.item.id)"
        >
          Assign
        </b-button>
      </template>
      <template #cell(assigned_to)="data">
        <span v-if="data.item.category"> Category : {{ data.item.category }}</span>
        <span v-if="data.item.entertainment">Entertainment : {{ data.item.entertainment }}</span>
        <span v-if="data.item.gift_card"> Gift Card : {{ data.item.gift_card }}</span>

      </template>
      <template #cell(salary)="data">

        {{ roundToTwo(data.item.salary) }}
      </template>
    </general-table>
    <div>
      <b-modal
        id="categories"
        ref="categories"
        hide-footer
        centered
        title="Assign to"
      >
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>
            <b-col md="12">
              <b-radio-group
                v-model="selectedRadio"
                class="my-2"
              >
                <b-form-radio
                  v-model="selectedRadio"
                  name="type"
                  value="category"
                >
                  Category
                </b-form-radio>

                <b-form-radio
                  v-model="selectedRadio"
                  name="type"
                  value="giftCard"
                >
                  Gift Card
                </b-form-radio>

                <b-form-radio
                  v-model="selectedRadio"
                  name="type"
                  value="entertainment"
                >
                  Entertainment
                </b-form-radio>

              </b-radio-group>
            </b-col>
            <b-col
              v-if="selectedRadio === 'giftCard'"
              md="12"
            >
              <validation-provider
                #default="validationContext"
                name="giftCard"
              >
                <b-form-group
                  label="Choose Gift Card"
                  label-for="giftCard"
                >
                  <div>
                    <v-select
                      v-model="categoriesForm.gift_card_id"
                      label="name"
                      :close-on-select="false"
                      :options="giftCards"
                      :filterable="false"
                      :searchable="false"
                      :reduce="giftCards => giftCards.id"
                    />
                  </div>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="selectedRadio === 'category'"
              md="12"
            >
              <validation-provider
                #default="validationContext"
                name="categories"
              >
                <b-form-group
                  label="Choose Category"
                  label-for="categories"
                >
                  <div>
                    <v-select
                      v-model="categoriesForm.game_category_id"
                      label="name"
                      :close-on-select="false"
                      :options="categories"
                      :filterable="false"
                      :searchable="false"
                      :reduce="categories => categories.id"
                    />
                  </div>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="selectedRadio === 'entertainment'"
              md="12"
            >
              <validation-provider
                #default="validationContext"
                name="entertainment"
              >
                <b-form-group
                  label="Choose entertainment"
                  label-for="Entertainment"
                >
                  <div>
                    <v-select
                      v-model="categoriesForm.entertainment_id"
                      label="name"
                      :close-on-select="false"
                      :options="entertainments"
                      :filterable="false"
                      :searchable="false"
                      :reduce="entertainments => entertainments.id"
                    />
                  </div>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>

        <b-col
          cols="12"
          md="12"
          class="mt-1"
        >
          <b-button
            v-if="!modalLoader"
            variant="primary"
            class="mr-1"
            @click.prevent="assignCampaign"
          >
            Assign
          </b-button>
          <b-button
            variant="outline-danger"
            class="closeModal"
            @click="closeModal()"
          >
            Cancel
          </b-button>
          <b-button
            v-if="modalLoader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select'
import GeneralTable from '@/components/general-table/GeneralTable.vue'
import handleFormData from '@/global-compositions/handleFormData'

export default {
  components: { GeneralTable, vSelect },
  data() {
    return {
      APIURL: 'gaming-products',
      type: 'page',
      guard: 'admin_users',
      selectedRadio: 'category',
      columns: [

        { key: 'id', sortable: true },
        { key: 'name' },
        { key: 'image' },
        { key: 'assigned_to' },

        { key: 'merchant_id' },
        { key: 'merchant_name' },
        { key: 'currency' },
        { key: 'salary' },
        { key: 'Assign' },
      ],

      loader: false,
      categoriesForm: {},
      categories: [],
      entertainments: [],
      giftCards: [],
      modalLoader: false,
      role: '',
    }
  },
  setup() {
    const { formData, setFormData } = handleFormData()

    return {
      formData, setFormData,
    }
  },
  mounted() {
    this.getCategories()
    this.getEntertainments()
    this.getGiftCards()
  },
  methods: {
    roundToTwo(num) {
      if (num) {
        return +(`${Math.round(`${num}e+2`)}e-2`)
      }
      return ''
    },
    openCategoryModal(id) {
      this.categoriesForm.game_category_id = ''
      this.categoriesForm.gift_card_id = ''
      this.categoriesForm.entertainment_id = ''
      this.categoriesForm.product_id = id
      this.$bvModal.show('categories')
    },
    closeModal() {
      this.$bvModal.hide('categories')
    },
    async getCategories() {
      await axios.get('gaming-categories').then(res => {
        if (res.status === 200) {
          this.categories = res.data?.data
        }
      })
    },
    async getEntertainments() {
      await axios.get('gaming-entertainments').then(res => {
        if (res.status === 200) {
          this.entertainments = res.data?.data
        }
      })
    },
    async getGiftCards() {
      await axios.get('gaming-giftCards').then(res => {
        if (res.status === 200) {
          this.giftCards = res.data?.data
        }
      })
    },
    assignCampaign() {
      this.modalLoader = true
      axios.post('gaming-assign-category', this.categoriesForm).then(res => {
        if (res.status === 200) {
          this.$refs.gamingProductsTable.getAllData()
          this.closeModal()
          this.$toasted.show('assigned Successfully', {
            position: 'top-center',
            duration: 3000,
          })
        }
      }).catch(error => {
        if (error.response.status === 422) {
          if (error.response.data.errors !== undefined) {
            this.errorMsg = error.response.data.errors
          } else {
            this.errorMsg = error.response.data.message
          }
        }
      }).finally(() => {
        this.modalLoader = false
      })
    },
  },
}
</script>

<style scoped>
.change-status {
  cursor: pointer;
}
</style>
